import { useAreaData, useMapPois } from './helper/queries'
import { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Polygon, Popup, TileLayer } from 'react-leaflet'
import { isMobile } from 'react-device-detect'
import MarkerClusterGroup from 'react-leaflet-cluster'
import Select from 'react-select'
import optionsData from './data/options.json'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import './overrides.css'

import {
  getPaddedBounds,
  mappingMarker,
  PoiData,
  swapCoords,
} from './helper/client'

export default function Map() {
  const mapRef = useRef<L.Map>(null)
  const [places, setPlaces] = useState<PoiData[]>([])
  const [contentLoading, setContentLoading] = useState(true)
  const [areaSelected, setAreaSelected] = useState<string>('muang-korat')
  const [padBounds, setPadBounds] = useState<L.LatLngBoundsExpression>([])
  const [mapCenter, setMapCenter] = useState<L.LatLngExpression>()
  const [activeCoords, setActiveCoords] = useState<L.LatLngExpression[][]>([])

  const {
    data: aData,
    error: aError,
    isLoading: aLoading,
  } = useAreaData({
    area: areaSelected,
  })

  const { data, status, error, isLoading } = useMapPois({
    provinceSlug: 'nakhon-ratchasima',
    areaSlug: aData?.slug,
    coordinates: aData?.coordinates,
  })

  useEffect(() => {
    if (!aData) return
    const newCenter: L.LatLngExpression = aData.center
    setMapCenter(newCenter)
    const coords: L.LatLngExpression[][] = swapCoords(aData.coordinates)
    const newBounds = getPaddedBounds(coords)
    setPadBounds(newBounds)
    setActiveCoords(coords)
    mapRef.current?.flyToBounds(newBounds, { animate: true })
  }, [aData])

  useEffect(() => {
    if (data && data.payload) {
      setPlaces(data.payload.places)
      setContentLoading(false)
    }
  }, [data])

  const openNavigation = (
    e: React.MouseEvent,
    location: PoiData['location']
  ) => {
    e?.preventDefault()
    if (location && location.lat && location.lng) {
      // open google map navigation to lat/lng
      const { lat, lng } = location
      window
        .open(
          `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
          '_blank'
        )
        ?.focus()
    }
  }

  const openPoiDetail = (e: React.MouseEvent, slug: string) => {
    e?.preventDefault()
    window.open(`https://www.tripniceday.com/place/${slug}`, '_blank')?.focus()
  }

  const handleAreaChange = (e: any) => {
    setContentLoading(true)
    setAreaSelected(e.value)
  }

  if (aLoading) return <div>Loading...</div>
  if (aError) return <div>{aError.message}</div>

  if (isLoading) return <div>Loading...</div>
  if (status !== 'pending' && error) {
    alert('ข้อมูลที่ส่งเข้ามาไม่ถูกต้อง')
    window.location.href = '/'
    return null
  }

  return (
    <div className='map-wrapper'>
      {/* topbar */}
      <div className='topbar'>
        <div className='left flex flex-row items-center gap-x-1'>
          <h1 className='text-lg leading-none'>จังหวัดนครราชสีมา</h1>
        </div>
        <div className='logo'>
          <img
            src='/logo/tat-and-tnd.png'
            alt='TAT & TripNiceDay'
            className='block h-14 max-w-full'
          />
        </div>
      </div>
      {/* map-block */}
      <div className='map-container relative'>
        {contentLoading ? <LoadingScreen /> : null}
        {/* dropdown */}
        <div className='area-selector absolute right-3 top-2.5 z-50 md:right-4 md:top-3'>
          <Select
            options={optionsData}
            defaultValue={
              optionsData.filter((option) => option.value === areaSelected)[0]
            }
            placeholder='เลือกอำเภอ'
            className='h-12 whitespace-pre'
            onChange={(e: any) => handleAreaChange(e)}
            styles={{
              valueContainer: (provided: any) => ({
                ...provided,
                height: '48px',
                paddingLeft: '16px',
              }),
              dropdownIndicator: (provided: any) => ({
                ...provided,
                color: 'black',
              }),
              control: (provided: any) => ({
                ...provided,
                background: '#ffffff',
                minWidth: '250px',
                height: '48px',
                borderRadius: '6px',
                border: '2px solid #cecece',
                fontFamily: 'TATSanachon',
                fontSize: '16px',
                fontWeight: 'bold',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
              }),
            }}
          />
        </div>
        {mapCenter && padBounds && activeCoords ? (
          <MapContainer
            center={mapCenter}
            zoom={isMobile ? 10 : 11}
            scrollWheelZoom={true}
            className='map-block'
            maxBoundsViscosity={1.0}
            ref={mapRef}
            attributionControl={false}>
            <TileLayer
              minZoom={9}
              maxZoom={17}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              // url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
              url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
              // url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'
              // url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
              // url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Polygon
              positions={activeCoords}
              fillOpacity={0.1}
              fillColor='#38849F'
              weight={3}
              color='#38849F'
            />
            {places && places.length > 0 ? (
              <MarkerClusterGroup
                chunkedLoading
                showCoverageOnHover={false}
                disableClusteringAtZoom={14}
                maxClusterRadius={40}
                spiderfyOnMaxZoom={false}>
                {places && places.length > 0
                  ? places.map((place, index) => {
                      const category = place.categories[0]
                      return (
                        <Marker
                          key={place.id}
                          position={[place.location.lat, place.location.lng]}
                          title={place.name}
                          draggable={false}
                          icon={mappingMarker(category.slug)}
                          alt={place.name}
                          zIndexOffset={1}
                          autoPan={true}>
                          <Popup
                            closeButton={false}
                            closeOnEscapeKey={true}
                            maxWidth={280}
                            minWidth={280}
                            className='map-infowindow'>
                            <div className='infowindow-wrapper'>
                              <div
                                className='place-cover'
                                style={{
                                  background: `black url(${place.image.thumbnail}) no-repeat center`,
                                  backgroundSize: 'cover',
                                }}
                              />
                              <div className='place-info'>
                                <div className='upper w-full'>
                                  <h3>
                                    <button
                                      title={place.name}
                                      onClick={(e) =>
                                        openPoiDetail(e, place.slug)
                                      }>
                                      {place.name}
                                    </button>
                                  </h3>
                                  <h4>{category.name}</h4>
                                </div>
                                <div className='lower w-full'>
                                  <p>{place.description}</p>
                                  <div className='flex w-full min-w-0 flex-row items-start justify-between gap-x-1'>
                                    <button
                                      onClick={(e) =>
                                        openNavigation(e, place.location)
                                      }
                                      type='button'
                                      className='button sm primary w-full'>
                                      นำทาง
                                    </button>
                                    <button
                                      onClick={(e) =>
                                        openPoiDetail(e, place.slug)
                                      }
                                      type='button'
                                      className='button sm secondary w-full'>
                                      ดูข้อมูล
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popup>
                        </Marker>
                      )
                    })
                  : null}
              </MarkerClusterGroup>
            ) : null}
          </MapContainer>
        ) : null}
      </div>
    </div>
  )
}

const LoadingScreen = () => {
  return (
    <div className='absolute inset-0 z-50 flex min-h-screen w-screen flex-col items-center justify-center'>
      <div className='absolute z-10 h-full w-full animate-pulse bg-white/70'></div>
      <h3 className='relative z-20 text-center text-2xl'>Loading</h3>
    </div>
  )
}
